/**======================================================================
=========================================================================
Template Name: Berry - Bootstrap Admin Template
Author: codedthemes
Support: https://codedthemes.authordesk.app
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import 'settings/color-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/variables-dark';
@import 'node_modules/bootstrap/scss/mixins';
$pc-body-bg: map-get($preset-colors, 'preset-1', 'body');
$pc-blue: map-get($preset-colors, 'preset-1', 'primary');
$pc-secondary: map-get($preset-colors, 'preset-1', 'secondary');
@import 'settings/bootstrap-variables';
@import 'settings/theme-variables';

section {
  padding: 100px 0;
}
.title {
  margin-bottom: 50px;
  h2 {
    font-weight: 700;
  }
}
.landing-page {
  overflow-x: hidden;
  @media (min-width: 1600px) {
    .container {
      max-width: 1200px;
    }
  }
}
.navbar {
  position: fixed;
  padding: 16px 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  backdrop-filter: blur(5px);
  &.top-nav-collapse {
    box-shadow: 0 8px 6px -10px rgba(0, 0, 0, 0.5);
  }

  &.default {
    top: 0;
    box-shadow: none;
  }
  .nav-link {
    font-size: 0.875rem;
    font-weight: 500;
  }
}
header {
  padding: 100px 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(360deg, rgb(238, 242, 246) 1.09%, rgb(255, 255, 255) 100%);
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../images/landing/img-header-bg.png');
    background-position: right bottom;
    background-size: 80%;
    background-repeat: no-repeat;

    z-index: 1;
  }
  > * {
    position: relative;
    z-index: 5;
  }
  .hero-image {
    position: relative;
    transform-origin: top left;
    transform: scale(1.6);
    .img-bg-grid {
      position: absolute;
      bottom: -20px;
      right: 140px;
      width: 70%;
      z-index: -1;
    }
    .img-widget-1,
    .img-widget-2 {
      filter: drop-shadow(rgba(33, 150, 243, 0.3) 0px 0px 50px);
      position: absolute;
      top: 0;
      left: 0;
    }
    .img-widget-1 {
      animation: 10s linear 2s infinite normal none running slideY;
    }
    .img-widget-2 {
      animation: 10s linear 0s infinite normal none running slideY;
    }
  }
  h1 {
    font-size: 64px;
  }
}
.feature-card {
  position: relative;
  overflow: hidden;
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    z-index: 1;
  }
  &::before {
    border: 2px solid rgb(255, 255, 255);
    opacity: 0.21;
    top: -97px;
    right: -3px;
  }
  &::after {
    border: 35px solid rgb(255, 255, 255);
    opacity: 0.4;
    top: -72px;
    right: -63px;
  }
  > * {
    position: relative;
    z-index: 5;
  }
  .avtar {
    background: rgba(255, 255, 255, 1);
    opacity: 0.5;
    img {
      width: 36px;
    }
  }
}

.customize-list {
  li {
    padding: 10px 0px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
}
.application-slider {
  overflow: hidden;
  &.bg-primary {
    --bs-bg-opacity: 0.07;
  }
}

.owl-theme {
  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    .owl-dot {
      display: inline-block;
      zoom: 1;
      span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: rgba(var(--bs-body-color-rgb), 0.3);
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 0.2s ease;
        border-radius: 30px;
      }
    }
    .owl-dot.active span,
    .owl-dot:hover span {
      background: rgba(var(--bs-body-color-rgb), 0.6);
    }
  }
}

.app-slider {
  &.swiper {
    overflow: visible;
  }
  .avtar {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    font-size: 36px;
    box-shadow: 0px 24px 38px rgba(28, 28, 29, 0.1);
    background: $white;
    color: var(--bs-body-color);
    &::after {
      display: none;
    }
    &.swiper-button-next {
      transform: translatex(150%);
    }
    &.swiper-button-prev {
      transform: translatex(-150%);
    }
  }
  img {
    transform: scale(0.8);
    border-radius: var(--bs-border-radius);
    margin: 35px 0;
    transition: all 0.2s ease-in-out;
  }

  .swiper-slide {
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    &.swiper-slide-active {
      opacity: 1;
      img {
        transform: scale(1);
        box-shadow: 0px 24px 38px rgba(28, 28, 29, 0.1);
      }
    }
  }
}

.testaments-cards {
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
  column-count: 1;
  position: relative;
  @include media-breakpoint-up(sm) {
    column-count: 2;
  }
  @include media-breakpoint-up(lg) {
    column-count: 3;
  }
  @include media-breakpoint-up(xl) {
    column-count: 4;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1), transparent);
    height: 50%;
  }
  .card {
    border: 1px solid var(--bs-border-color);
    display: inline-block;
    width: 100%;
  }
}
.choose-section {
  padding-bottom: 0;
  padding-top: 30px;
  .hand-img {
    width: 220px;
    filter: drop-shadow(rgba(33, 150, 243, 0.3) 0px 0px 50px);
  }
  h2 {
    font-size: 2.5rem;
  }
}
.choose-slider {
  position: relative;
  width: 100%;
  height: 200px;
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px !important;
    opacity: 0.08;
    transition: all 0.2s ease-in-out;
    h2 {
      margin-bottom: 0;
      color: #fff;
    }
    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 0.2;
    }
    &.swiper-slide-active {
      opacity: 1;
      h2 {
        color: var(--bs-primary);
      }
    }
  }
}
.frameworks-card {
  background: var(--bs-body-bg);
  text-align: center;
  position: relative;
  margin: 10px 0 35px;
  &:hover {
    background: rgba(var(--bs-primary-rgb), 0.08);
    box-shadow: 0 4px 15px 0 rgba(var(--bs-primary-rgb), 0.15);
  }
  .frameworks-logo {
    width: auto;
    height: 48px;
    margin-bottom: 16px;
  }
  .frameworks-badge {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
    font-weight: 500;
    background: var(--bs-card-bg);
    border: 1px solid var(--bs-primary);
    color: var(--bs-primary);
    border-radius: 30px;
    padding: 0 5px;
    white-space: nowrap;
  }
}
.frameworks-section {
  overflow: hidden;
  .frameworks-slider.swiper {
    overflow: visible;
  }
}

.footer {
  padding: 100px 0 0;
  .sub-footer {
    background: rgba(255, 255, 255, 0.05);
    padding: 20px 0px;
    margin-top: 100px;
  }
  .footer-link {
    a {
      display: inline-flex;
      align-items: center;
      transition: all 0.1s ease-in-out;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 20px;
      i {
        font-size: 20px;
        margin-right: 5px;
      }
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}
@keyframes updown {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
@media (max-width: 991.98px) {
  header {
    text-align: center;
  }
  section {
    padding: 40px 0;
  }
  .choose-section h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  header {
    padding: 100px 0 50px;
    h1 {
      font-size: 30px;
    }
  }
  .app-slider {
    .avtar {
      display: none;
    }
  }
  .choose-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .footer .footer-link a {
    margin-top: 8px;
  }
}
@media (max-width: 575.98px) {
  .choose-section h2 {
    font-size: 1.1rem;
  }
  .choose-slider {
    height: 120px;
    .swiper-slide {
      height: 30px !important;
    }
  }
}
[data-pc-direction='rtl'] {
  header .hero-image {
    transform-origin: top right;
  }
}
[data-pc-theme='dark'] {
  header {
    background: rgb(17, 25, 54);
  }
  @each $name, $value in $preset-colors {
    &[data-pc-preset='#{$name}'] {
      $pc-primary: map-get($value, 'primary');
      $pc-secondary: map-get($value, 'secondary');
      $pctheme-colors: (
        'primary': $pc-primary,
        'secondary': $pc-secondary
      );
      $pc-body: map-get($dark-preset-colors, $name, 'body');
      .app-slider .avtar {
        background: $pc-body;
      }
      .navbar.navbar-light {
        .navbar-toggler-icon {
          filter: invert(1) grayscale(100%) brightness(200%);
        }
        &:not(.card) {
          background: transparentize($pc-body, 0.2);
        }
      }
    }
  }
}
