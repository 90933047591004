/**======================================================================
=========================================================================
Template Name: Berry - Bootstrap Admin Template
Author: codedthemes
Support: https://codedthemes.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

:root {
  --bs-body-bg: #eceff1;
  --bs-body-bg-rgb: 236, 239, 241;
  --pc-heading-color: #343a40;
  --pc-active-background: #e9ecef;
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #616161;
  --pc-sidebar-color-rgb: 57, 70, 95;
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #6610f2;
  --pc-sidebar-active-color-rgb: 102, 16, 242;
  --pc-sidebar-shadow: none;
  --pc-sidebar-caption-color: #212121;
  --pc-sidebar-border: none;
  --pc-header-background: #fff;
  --pc-header-color: #616161;
  --pc-header-shadow: none;
  --pc-card-box-shadow: none;
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #111936;
}

[data-pc-sidebar-theme=dark] {
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}

section {
  padding: 100px 0;
}

.title {
  margin-bottom: 50px;
}
.title h2 {
  font-weight: 700;
}

.landing-page {
  overflow-x: hidden;
}
@media (min-width: 1600px) {
  .landing-page .container {
    max-width: 1200px;
  }
}

.navbar {
  position: fixed;
  padding: 16px 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  backdrop-filter: blur(5px);
}
.navbar.top-nav-collapse {
  box-shadow: 0 8px 6px -10px rgba(0, 0, 0, 0.5);
}
.navbar.default {
  top: 0;
  box-shadow: none;
}
.navbar .nav-link {
  font-size: 0.875rem;
  font-weight: 500;
}

header {
  padding: 100px 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(360deg, rgb(238, 242, 246) 1.09%, rgb(255, 255, 255) 100%);
  overflow: hidden;
  position: relative;
}
header:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/landing/img-header-bg.png");
  background-position: right bottom;
  background-size: 80%;
  background-repeat: no-repeat;
  z-index: 1;
}
header > * {
  position: relative;
  z-index: 5;
}
header .hero-image {
  position: relative;
  transform-origin: top left;
  transform: scale(1.6);
}
header .hero-image .img-bg-grid {
  position: absolute;
  bottom: -20px;
  right: 140px;
  width: 70%;
  z-index: -1;
}
header .hero-image .img-widget-1,
header .hero-image .img-widget-2 {
  filter: drop-shadow(rgba(33, 150, 243, 0.3) 0px 0px 50px);
  position: absolute;
  top: 0;
  left: 0;
}
header .hero-image .img-widget-1 {
  animation: 10s linear 2s infinite normal none running slideY;
}
header .hero-image .img-widget-2 {
  animation: 10s linear 0s infinite normal none running slideY;
}
header h1 {
  font-size: 64px;
}

.feature-card {
  position: relative;
  overflow: hidden;
}
.feature-card::after, .feature-card::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 1;
}
.feature-card::before {
  border: 2px solid rgb(255, 255, 255);
  opacity: 0.21;
  top: -97px;
  right: -3px;
}
.feature-card::after {
  border: 35px solid rgb(255, 255, 255);
  opacity: 0.4;
  top: -72px;
  right: -63px;
}
.feature-card > * {
  position: relative;
  z-index: 5;
}
.feature-card .avtar {
  background: rgb(255, 255, 255);
  opacity: 0.5;
}
.feature-card .avtar img {
  width: 36px;
}

.customize-list li {
  padding: 10px 0px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.application-slider {
  overflow: hidden;
}
.application-slider.bg-primary {
  --bs-bg-opacity: 0.07;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: rgba(var(--bs-body-color-rgb), 0.3);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: rgba(var(--bs-body-color-rgb), 0.6);
}

.app-slider.swiper {
  overflow: visible;
}
.app-slider .avtar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  font-size: 36px;
  box-shadow: 0px 24px 38px rgba(28, 28, 29, 0.1);
  background: #ffffff;
  color: var(--bs-body-color);
}
.app-slider .avtar::after {
  display: none;
}
.app-slider .avtar.swiper-button-next {
  transform: translatex(150%);
}
.app-slider .avtar.swiper-button-prev {
  transform: translatex(-150%);
}
.app-slider img {
  transform: scale(0.8);
  border-radius: var(--bs-border-radius);
  margin: 35px 0;
  transition: all 0.2s ease-in-out;
}
.app-slider .swiper-slide {
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}
.app-slider .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.app-slider .swiper-slide.swiper-slide-active img {
  transform: scale(1);
  box-shadow: 0px 24px 38px rgba(28, 28, 29, 0.1);
}

.testaments-cards {
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
  column-count: 1;
  position: relative;
}
@media (min-width: 576px) {
  .testaments-cards {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .testaments-cards {
    column-count: 3;
  }
}
@media (min-width: 1200px) {
  .testaments-cards {
    column-count: 4;
  }
}
.testaments-cards::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgb(255, 255, 255), transparent);
  height: 50%;
}
.testaments-cards .card {
  border: 1px solid var(--bs-border-color);
  display: inline-block;
  width: 100%;
}

.choose-section {
  padding-bottom: 0;
  padding-top: 30px;
}
.choose-section .hand-img {
  width: 220px;
  filter: drop-shadow(rgba(33, 150, 243, 0.3) 0px 0px 50px);
}
.choose-section h2 {
  font-size: 2.5rem;
}

.choose-slider {
  position: relative;
  width: 100%;
  height: 200px;
}
.choose-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px !important;
  opacity: 0.08;
  transition: all 0.2s ease-in-out;
}
.choose-slider .swiper-slide h2 {
  margin-bottom: 0;
  color: #fff;
}
.choose-slider .swiper-slide.swiper-slide-prev, .choose-slider .swiper-slide.swiper-slide-next {
  opacity: 0.2;
}
.choose-slider .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.choose-slider .swiper-slide.swiper-slide-active h2 {
  color: var(--bs-primary);
}

.frameworks-card {
  background: var(--bs-body-bg);
  text-align: center;
  position: relative;
  margin: 10px 0 35px;
}
.frameworks-card:hover {
  background: rgba(var(--bs-primary-rgb), 0.08);
  box-shadow: 0 4px 15px 0 rgba(var(--bs-primary-rgb), 0.15);
}
.frameworks-card .frameworks-logo {
  width: auto;
  height: 48px;
  margin-bottom: 16px;
}
.frameworks-card .frameworks-badge {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
  font-weight: 500;
  background: var(--bs-card-bg);
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  border-radius: 30px;
  padding: 0 5px;
  white-space: nowrap;
}

.frameworks-section {
  overflow: hidden;
}
.frameworks-section .frameworks-slider.swiper {
  overflow: visible;
}

.footer {
  padding: 100px 0 0;
}
.footer .sub-footer {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px 0px;
  margin-top: 100px;
}
.footer .footer-link a {
  display: inline-flex;
  align-items: center;
  transition: all 0.1s ease-in-out;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 20px;
}
.footer .footer-link a i {
  font-size: 20px;
  margin-right: 5px;
}
.footer .footer-link a:hover {
  color: rgb(255, 255, 255);
}

@keyframes slideY {
  0%, 50%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}
@keyframes updown {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
@media (max-width: 991.98px) {
  header {
    text-align: center;
  }
  section {
    padding: 40px 0;
  }
  .choose-section h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  header {
    padding: 100px 0 50px;
  }
  header h1 {
    font-size: 30px;
  }
  .app-slider .avtar {
    display: none;
  }
  .choose-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .footer .footer-link a {
    margin-top: 8px;
  }
}
@media (max-width: 575.98px) {
  .choose-section h2 {
    font-size: 1.1rem;
  }
  .choose-slider {
    height: 120px;
  }
  .choose-slider .swiper-slide {
    height: 30px !important;
  }
}
[data-pc-direction=rtl] header .hero-image {
  transform-origin: top right;
}

[data-pc-theme=dark] header {
  background: rgb(17, 25, 54);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .app-slider .avtar {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .navbar.navbar-light:not(.card) {
  background: rgba(17, 25, 54, 0.8);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .app-slider .avtar {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .navbar.navbar-light:not(.card) {
  background: rgba(14, 27, 35, 0.8);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .app-slider .avtar {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .navbar.navbar-light:not(.card) {
  background: rgba(10, 15, 35, 0.8);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .app-slider .avtar {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .navbar.navbar-light:not(.card) {
  background: rgba(1, 6, 6, 0.8);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .app-slider .avtar {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .navbar.navbar-light:not(.card) {
  background: rgba(3, 7, 8, 0.8);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .app-slider .avtar {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .navbar.navbar-light:not(.card) {
  background: rgba(5, 19, 39, 0.8);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .app-slider .avtar {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .navbar.navbar-light:not(.card) {
  background: rgba(26, 34, 63, 0.8);
}